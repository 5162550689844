<template>
  <div class="form-narrow">
    <div class="form-row">
      <div class="form-col">
        <label class="label">location name</label>
        <div>{{initialValues.name}}</div>
      </div>
      <div class="form-col">
        <label class="label">location id</label>
        <div>{{initialValues.id}}</div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <label class="label">customer id</label>
        <div>{{initialValues.customerId}}</div>
      </div>
      <div class="form-col">
        <label class="label">community id</label>
        <div>{{initialValues.communityId}}</div>
      </div>
    </div>

    <ModalFooter :footer="footer" :tertiary="cancel" :primary="cancel" />
  </div>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ModalFooter from "@/components/ui/modals/ModalFooter";

  export default {
    name: "LocationDetails",
    components: {
      ModalFooter,
    },
    mixins: [
      ModalNavigation,
    ],
    props: {
      initialValues: {
        type: Object,
        default: () => ({})
      },
    },
    data(){
      return {
        footer: {
          tertiaryButton: 'back'
        },
      }
    },
    methods: {
      cancel(e) {
        e.preventDefault();
        this.close();
      },
    },
  }
</script>
<style scoped>

</style>
